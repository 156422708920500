<template>
  <div class="mallDetail">
    <div class="introImg">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <div
          class="oSwiper"
          v-for="(item, index) in goodsDetail.pictures"
          :key="index"
        >
          <van-swipe-item><img :src="item" alt="" /></van-swipe-item>
        </div>
      </van-swipe>
    </div>
    <div class="mallDetail-title">{{ goodsDetail.title }}</div>
    <div class="points">
      <div class="points-l">{{ goodsDetail.pointsPrice }}积分</div>
      <div class="points-r"></div>
    </div>
    <div class="selectKind" @click="showDialog">
      <div class="selectKind-l">选择规格/数量</div>
      <div class="selectKind-r"><img src="@/assets/img/tip.png" alt="" /></div>
    </div>
    <div class="line"></div>
    <div class="detail">
      <div class="detailTitle">产品详情</div>
      <div class="body" v-html="goodsDetail.detailContent"></div>
    </div>
    <div class="exchange" @click="showDialog">立即兑换</div>
    <v-popup v-model="isPopup">
      <div class="dialog">
        <div class="kinds">
          <div class="kinds-t">
            <div class="kinds-tl">
              <img :src="goodsDetail.kindImg" alt="" />
            </div>
            <div class="kinds-tr">
              <div class="title">{{ goodsDetail.title }}</div>
              <div class="price">
                {{ goodsDetail.pointsPrice }}积分（库存 :{{
                  goodsDetail.stockKind
                }}）
              </div>
            </div>
          </div>
          <div class="kinds-c">
            <!-- <div class="title">颜色</div> -->
            <div class="kindsBody">
              <div
                v-for="(item, index) in goodsDetail.skuList"
                :class="['item', { active: item.isSelected }]"
                @click="selectKind(item, index)"
                :key="index"
              >
                {{ item.skuName }}
              </div>
            </div>
          </div>
          <div class="kinds-b">
            <div class="title">数量</div>
            <div class="kindsBody">
              <v-stepper
                v-model="form.num"
                :max="goodsDetail.stockKind"
              ></v-stepper>
            </div>
          </div>
        </div>
        <div class="change" @click="changeTip">立即兑换</div>
      </div>
    </v-popup>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <div class="tips">
        <div class="tips-t">
          确定要花{{ total }}积分兑换{{ form.num }}个{{
            goodsDetail.skuName
          }}商品？
        </div>
        <div class="tips-b">
          <div class="tips-bl">
            <img src="@/assets/img/notice.png" alt="" />
          </div>
          <div class="tips-br">兑换成功后，不接受退换货处理</div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getGoodsDetailUrl, getChangeUrl } from "./api.js";
import { toRegister } from "@/utils/common.js";
import { handleImg } from "@/utils/utils.js";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { gloabalCount } from "@/utils/common.js";
Vue.use(Swipe);
Vue.use(SwipeItem);
var moment = require("moment");
export default {
  name: "points",
  data() {
    return {
      isPopup: false,
      isDialog: false,
      form: {
        num: 1,
        id: "",
        communityId: "",
        roomId: "",
        userId: "",
        tenantId: "",
        type: "scene",
        skuId: "",
      },
      id: "",
      goodsDetail: {},
      orderId: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    total() {
      if (this.form.num || this.form.num == 0) {
        return (
          Number(this.form.num) *
          Number(this.goodsDetail.pointsPrice).toFixed(2)
        );
      } else {
        return "";
      }
    },
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getGoodsDetail();
    gloabalCount(this.id, 18, 1);
  },
  methods: {
    changeTip() {
      if (this.form.num == 0) {
        this.$toast({ message: "请选择购买数量", duration: 2000 });
        return;
      }
      this.isPopup = false;
      this.isDialog = true;
    },
    selectKind(item, indexs) {
      console.log(this.goodsDetail.skuList, 22);
      if (this.goodsDetail.skuList && this.goodsDetail.skuList.length) {
        this.goodsDetail.skuList.forEach((ele, index) => {
          this.goodsDetail.skuList[index].isSelected = false;
        });
        this.goodsDetail.skuList[indexs].isSelected = true;
        this.goodsDetail.kindImg = item.skuImageUrl;
        this.form.skuId = item.id;
        this.goodsDetail.stockKind = item.stock;
        this.goodsDetail.skuName = item.skuName;
        if (this.goodsDetail.stockKind <= 0) {
          this.form.num = 0;
        } else {
          this.form.num = 1;
        }
      }
      this.$forceUpdate();
    },
    async showDialog() {
      if (
        await toRegister(this.$route.path, this.$route.query, "积分商城兑换")
      ) {
        this.isPopup = true;
      }
    },
    confirm() {
      this.changeGoods();
    },
    changeGoods() {
      let params = {
        num: this.form.num,
        id: this.id,
        communityId: this.communityId,
        roomId: this.roomId,
        userId: this.userId,
        tenantId: this.tenantId,
        type: "scene",
        skuId: this.form.skuId,
      };

      this.$axios.post(`${getChangeUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.orderId = res.data.id;
          this.$toast({ message: "兑换成功", duration: 2000 });
          this.$router.replace({
            name: "mallFinished",
            query: {
              id: this.orderId,
            },
          });
        } else {
        }
      });
    },
    getGoodsDetail() {
      let params = {
        id: this.id,
        userId: this.userId,
      };

      this.$axios.get(`${getGoodsDetailUrl}`, { params }).then((res) => {
        console.log(res, 5555);
        if (res.code === 200) {
          this.goodsDetail = res.data;
          if (this.goodsDetail.pictures) {
            this.goodsDetail.pictures = this.goodsDetail.pictures.split(",");
            if (this.goodsDetail.pictures.length) {
              // this.goodsDetail.pictures.forEach((ele, index) => {
              //   this.goodsDetail.pictures[index] = ele.ossResize(340, 600);
              // });
            }
          }
          if (this.goodsDetail.imageUrl) {
            this.goodsDetail.imageUrl = handleImg(
              340,
              340,
              this.goodsDetail.imageUrl
            );
          }
          if (this.goodsDetail.skuList && this.goodsDetail.skuList.length) {
            this.goodsDetail.skuList.forEach((ele, index) => {
              ele.skuImageUrl = handleImg(340, 340, ele.skuImageUrl);
              ele.isSelected = false;
            });
            this.goodsDetail.skuList[0].isSelected = true;
            this.goodsDetail.kindImg = this.goodsDetail.skuList[0].skuImageUrl;
            this.form.skuId = this.goodsDetail.skuList[0].id;
            this.goodsDetail.stockKind = this.goodsDetail.skuList[0].stock;
            this.goodsDetail.skuName = this.goodsDetail.skuList[0].skuName;
            if (this.goodsDetail.stockKind <= 0) {
              this.form.num = 0;
            }
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mallDetail {
  padding: 40px 40px 120px;
  .introImg {
    height: calc(100vw / 16 * 9);
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    img {
      height: 100%;
    }
  }
  .mallDetail-title {
    font-size: 34px;
    margin: 40px 0;
  }
  .points {
    display: flex;
    color: rgba(0, 126, 255, 1);
    font-size: 32px;
    margin: 40px 0;
    justify-content: space-between;
  }
  .selectKind {
    width: 100%;
    height: 60px;
    background: #f7f7f7;
    padding: 40px;
    box-sizing: border-box;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    .selectKind-r {
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .line {
    width: 100%;
    background: #f7f7f7;
    height: 2px;
    margin: 60px 0;
  }
  .detail {
    .detailTitle {
      font-size: 34px;
      margin: 40px 0;
    }
  }
  .exchange {
    width: 90%;
    box-sizing: border-box;
    height: 90px;
    border-radius: 10px;
    background: rgba(0, 126, 255, 1);
    font-size: 32px;
    line-height: 90px;
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .dialog {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    .change {
      width: 90%;
      box-sizing: border-box;
      height: 90px;
      border-radius: 10px;
      background: rgba(0, 126, 255, 1);
      font-size: 32px;
      line-height: 90px;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .kinds {
    padding: 40px 40px 180px;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
    .title {
      font-size: 34px;
      margin: 40px 0;
    }
    .kinds-t {
      display: flex;
    }
    .kinds-tl {
      width: 200px;
      height: 200px;
      margin-right: 20px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
    .kinds-tr {
      font-size: 34px;
      .title {
        margin: 40px 0;
      }
      .price {
        font-size: 30px;
        color: rgba(0, 126, 255, 1);
      }
    }
    .kinds-c {
      margin: 80px 0 0;
      .kindsBody {
        display: flex;
        flex-wrap: wrap;
        .item {
          font-size: 30px;
          border-radius: 10px;
          padding: 10px 40px;
          margin: 0 20px 20px 0;
          border: 1px solid #999;
          color: #999;
        }
        .active {
          border: 1px solid rgba(0, 126, 255, 1);
          color: rgba(0, 126, 255, 1);
        }
      }
    }
  }
  .tips {
    .tips-t {
      font-size: 34px;
      text-align: center;
      padding: 0 20px;
      margin: 40px 0;
    }
    .tips-b {
      display: flex;
      margin: 40px 0;
      justify-content: center;
      align-items: center;
      .tips-bl {
        width: 30px;
        height: 30px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tips-br {
        font-size: 28px;
      }
    }
  }
}
</style>
<style lang="less">
.mallDetail {
  .body {
    padding: 20px 0;
    font-size: 30px;

    img {
      width: 100%;
    }
    font-size: 30px !important;
    p,
    h1,
    span {
      font-size: 30px !important;
    }
  }
  .van-swipe-item {
    text-align: center;
  }
}
</style>
